import React from 'react';
import { useTranslate } from 'react-translate';
import { Helmet } from 'react-helmet';
import imageUrl from 'assets/images/preview.png';

const HelmetComponent = ({ page = '', name, declensions, meta, metaImageUrl }) => {
  const t = useTranslate('HELMET');
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);

  const metaTags = React.useMemo(
    () => ({
      home: {
        title: t('homeTitle'),
        description: t('homeDescription'),
        keywords: t('homeKeywords'),
        ogTitle: t('homeOgTitle'),
        ogDescription: t('homeOgDescription')
      },
      professions: {
        title: t('professionsTitle', { currentYear }),
        description: t('professionsDescription'),
        keywords: t('professionsKeywords'),
        ogTitle: t('professionsOgTitle'),
        ogDescription: t('professionsOgDescription')
      },
      profession: {
        title: t('professionTitle', { name, currentYear }),
        description: t('professionDescription', { name }),
        keywords: t('professionKeywords', {
          genitive: declensions?.genitive,
          instrumental: declensions?.instrumental
        }),
        ogTitle: t('professionOgTitle', { name }),
        ogDescription: t('professionOgDescription', { name })
      },
      '': {
        title: meta?.title || t('homeTitle'),
        description: meta?.description || t('homeDescription'),
        keywords: t('homeKeywords'),
        ogTitle: meta?.title || t('homeOgTitle'),
        ogDescription: meta?.description || t('homeOgDescription')
      }
    }),
    [currentYear, t, declensions, name, meta]
  );

  return (
    <Helmet>
      <title>{metaTags[page].title}</title>
      <meta name="keywords" content={metaTags[page].keywords} />
      <meta name="description" content={metaTags[page].description} />
      <meta property="og:title" content={metaTags[page].ogTitle} />
      <meta property="og:description" content={metaTags[page].ogDescription} />
      <meta property="og:image" content={metaImageUrl || imageUrl} />
      <meta property="og:image:secure_url" content={metaImageUrl || imageUrl} />
      <meta property="og:type" content={'article'} />
      <meta property="og:url" content={window.location.href} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default HelmetComponent;
