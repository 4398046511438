import React, { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import cleenDeep from 'clean-deep';
import Dialog from '@mui/material/Dialog';
import styled from '@emotion/styled';
import storage from 'helpers/storage';
import parseQS from 'helpers/parseQS';
import { createOnboarding, updateOnboarding } from 'actions/onboarding';
import Start from './steps/Start';
const LogoColored = lazy(() =>
  import('assets/images/Logo_colored.svg').then((m) => ({ default: m.ReactComponent }))
);
const Stepper = lazy(() => import('./components/Stepper'));
const KnowWhatTodo = lazy(() => import('./steps/KnowWhatTodo'));
const TimePerDay = lazy(() => import('./steps/TimePerDay'));
const InformationType = lazy(() => import('./steps/InformationType'));
const NeedSupport = lazy(() => import('./steps/NeedSupport'));
const Notifications = lazy(() => import('./steps/Notifications'));
const SpheresStep = lazy(() => import('./steps/Spheres'));
const IntroStep = lazy(() => import('./steps/Intro'));
const GenderStep = lazy(() => import('./steps/GenderChoose'));
const NameStep = lazy(() => import('./steps/NameChoose'));
const GreetingsStep = lazy(() => import('./steps/Greetings'));
const AgeStep = lazy(() => import('./steps/AgeChoose'));
const Benefits = lazy(() => import('./steps/Benefits'));
const StrengthsStep = lazy(() => import('./steps/Strengths'));
const GetTrollarsStep = lazy(() => import('./steps/GetTrollars'));
const DoingMyJubStep = lazy(() => import('./steps/DoingMyJub'));
const SubscribeType = lazy(() => import('./steps/SubscribeType'));
const RegistrationStep = lazy(() => import('./steps/Registration'));

const ContentBackground = styled('section')(({ theme }) => ({
  borderBottom: `3px solid ${theme.palette.primary.main}`
}));

const Content = styled('div')(({ theme }) => ({
  padding: '40px 100px',
  ...theme.wrapper,
  [theme.breakpoints.down('lg')]: {
    padding: 50
  },
  [theme.breakpoints.down('md')]: {
    padding: 30
  },
  [theme.breakpoints.down('sm')]: {
    padding: 20
  }
}));

const Wrapper = styled('div')(() => ({
  marginBottom: 20
}));

const WrapperDialog = styled('div')(({ theme, maxWidth, width }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 4,
  maxWidth: maxWidth || 820,
  width: width || '100%',
  gap: 20,
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    maxWidth: '100%',
    width: '100%'
  }
}));

const LogoStyled = styled(LogoColored)(() => ({
  cursor: 'pointer',
  flex: 1,
  marginBottom: 20
}));

const DialogStyled = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    alignItems: 'center'
  }
}));

const MaxWidth = styled('div')(({ theme, fullHeight, fullHeightSm }) => ({
  width: 820,
  borderRadius: 0,
  height: fullHeight === 'true' ? 'unset' : '100vh',
  marginTop: fullHeight === 'true' ? 20 : 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 20,
    height: fullHeightSm === 'true' ? 'unset' : '100vh'
  }
}));

const TOTAL_STEPS = 17;
const ONBOARDING_STEPS = Array.from({ length: TOTAL_STEPS }, (_, i) => i + 1);

const Onboarding = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [highlightStep, setHighlightStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [onboardingData, setOnboardingData] = React.useState(() => {
    const onboarding = storage.getItem('onboarding');
    const parsed = JSON.parse(onboarding);
    return onboarding
      ? cleenDeep({
          ...parsed,
          age: parsed?.birthdate ? dayjs(parsed?.birthdate).format('DD.MM.YYYY') : null
        })
      : {};
  });
  const [onboardingId, setOnboardingId] = React.useState(null);
  const [steps] = React.useState(ONBOARDING_STEPS);

  const handleCreateOnboarding = React.useCallback(async () => {
    if (onboardingData._id) {
      setOnboardingId(onboardingData._id);
      return;
    }
    const result = await dispatch(createOnboarding());
    setOnboardingId(result._id);
  }, [onboardingData, dispatch]);

  const handleUpdateOnboarding = React.useCallback(async () => {
    const body = cleenDeep({
      gender: onboardingData?.gender,
      name: onboardingData?.name,
      confidenceInProfession: onboardingData?.confidenceInProfession,
      spendTimeOpportunity: onboardingData?.spendTimeOpportunity,
      informationPerception: onboardingData?.informationPerception,
      needSupport: onboardingData?.needSupport,
      spheres: onboardingData?.spheres,
      strengths: onboardingData?.strengths,
      birthdate: onboardingData?.age
        ? dayjs(onboardingData?.age, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : null
    });

    if (Object.keys(body).length === 0) {
      return;
    }

    const result = await dispatch(updateOnboarding(onboardingId, body));
    storage.setItem('onboarding', JSON.stringify(result));
  }, [dispatch, onboardingData, onboardingId]);

  const handleStop = React.useCallback(() => {
    setOpen(false);
    setActiveStep(0);
  }, []);

  const handleIncrementStep = React.useCallback(() => {
    setActiveStep(activeStep + 1);

    setHighlightStep(() => {
      if (activeStep >= 1 && activeStep <= 12) {
        return Math.ceil(activeStep / 2);
      }
      return 0;
    });
  }, [activeStep]);

  const handleOpenFullScreen = React.useCallback(() => {
    setOpen(true);
    handleIncrementStep();
  }, [handleIncrementStep]);

  const FullScreenWrapper = React.useCallback(
    ({ children, maxWidth, width, fullHeight, fullHeightSm }) => (
      <DialogStyled fullScreen={true} open={open} transitionDuration={0}>
        <MaxWidth fullHeightSm={fullHeightSm} fullHeight={`${fullHeight}`}>
          <LogoStyled onClick={handleStop} />

          <WrapperDialog width={width} maxWidth={maxWidth}>
            {children}
          </WrapperDialog>

          <Stepper
            handleStop={handleStop}
            steps={steps.slice(0, Math.ceil(steps.length / 2))}
            activeStep={activeStep}
            highlightStep={highlightStep}
            setStep={setActiveStep}
          />
        </MaxWidth>
      </DialogStyled>
    ),
    [open, steps, activeStep, handleStop, highlightStep, setActiveStep]
  );

  React.useEffect(() => {
    if (onboardingId) {
      handleUpdateOnboarding();
    }
  }, [handleUpdateOnboarding, onboardingId]);

  React.useEffect(() => {
    const { step } = parseQS(location.search);

    if (step) {
      setActiveStep(Number(step));
      setOpen(true);
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location]);

  React.useEffect(() => {
    const mainLayout = document.getElementById('root');
    mainLayout.style.opacity = open ? 0 : 1;
    return () => {
      mainLayout.style.opacity = 1;
    };
  }, [open]);

  return (
    <>
      <ContentBackground>
        <Content>
          <Wrapper>
            {activeStep === 0 ? <Start handleOpen={handleOpenFullScreen} /> : null}

            {activeStep === 1 ? (
              <FullScreenWrapper>
                <IntroStep
                  handleIncrementStep={handleIncrementStep}
                  handleCreateOnboarding={handleCreateOnboarding}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 2 ? (
              <FullScreenWrapper>
                <NameStep
                  handleIncrementStep={handleIncrementStep}
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 3 ? (
              <FullScreenWrapper>
                <GreetingsStep
                  handleIncrementStep={handleIncrementStep}
                  onboardingData={onboardingData}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 4 ? (
              <FullScreenWrapper>
                <KnowWhatTodo
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 5 ? (
              <FullScreenWrapper>
                <GenderStep
                  handleIncrementStep={handleIncrementStep}
                  handleCreateOnboarding={handleCreateOnboarding}
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 6 ? (
              <FullScreenWrapper>
                <StrengthsStep
                  handleIncrementStep={handleIncrementStep}
                  handleCreateOnboarding={handleCreateOnboarding}
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 7 ? (
              <FullScreenWrapper fullHeightSm={'true'}>
                <Benefits handleIncrementStep={handleIncrementStep} />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 8 ? (
              <FullScreenWrapper>
                <AgeStep
                  fullHeightSm={'true'}
                  handleIncrementStep={handleIncrementStep}
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 9 ? (
              <FullScreenWrapper>
                <GetTrollarsStep handleIncrementStep={handleIncrementStep} />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 10 ? (
              <FullScreenWrapper>
                <TimePerDay
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 11 ? (
              <FullScreenWrapper>
                <InformationType
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 12 ? (
              <FullScreenWrapper>
                <NeedSupport
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 13 ? (
              <FullScreenWrapper>
                <Notifications
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 14 ? (
              <FullScreenWrapper>
                <DoingMyJubStep handleIncrementStep={handleIncrementStep} />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 15 ? (
              <FullScreenWrapper>
                <SpheresStep
                  onboardingData={onboardingData}
                  setValue={setOnboardingData}
                  handleIncrementStep={handleIncrementStep}
                />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 16 ? (
              <FullScreenWrapper fullHeight={true} width={'1200px'} maxWidth={'1200px'}>
                <SubscribeType handleIncrementStep={handleIncrementStep} />
              </FullScreenWrapper>
            ) : null}

            {activeStep === 17 ? (
              <FullScreenWrapper>
                <RegistrationStep
                  handleIncrementStep={handleIncrementStep}
                  handleCreateOnboarding={handleCreateOnboarding}
                />
              </FullScreenWrapper>
            ) : null}
          </Wrapper>
        </Content>
      </ContentBackground>
    </>
  );
};

export default Onboarding;
