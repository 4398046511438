import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
const CookieConsent = lazy(() => import('components/CookieConsent'));
const Professions = lazy(() => import('pages/Professions'));
const Profession = lazy(() => import('pages/Profession'));
const SignInScreen = lazy(() => import('pages/SignIn'));
const SignUpScreen = lazy(() => import('pages/SignUp'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Profile = lazy(() => import('pages/Profile'));
const Posts = lazy(() => import('pages/Posts'));
const Post = lazy(() => import('pages/Post'));
const Policy = lazy(() => import('pages/Policy'));
const Company = lazy(() => import('pages/Company'));
const Consultants = lazy(() => import('pages/Consultants'));
const Consultant = lazy(() => import('pages/Consultant'));
const Mentors = lazy(() => import('pages/Mentors'));
const Mentor = lazy(() => import('pages/Mentor'));
const RefundPolicy = lazy(() => import('pages/Policy/Refund'));
const PrivacyPolicy = lazy(() => import('pages/Policy/Privacy'));
const CookiePolicy = lazy(() => import('pages/Policy/Cookie'));
const Order = lazy(() => import('pages/Order'));
const Orders = lazy(() => import('pages/Orders'));
const Page404 = lazy(() => import('pages/404'));
const SubscribeType = lazy(() => import('pages/SubscribeType'));
const ChatPage = lazy(() => import('pages/Chat'));
const UniversityPage = lazy(() => import('pages/University'));
const AboutPage = lazy(() => import('pages/AboutUs'));
const Programs = lazy(() => import('pages/Programs'));
const Vacancies = lazy(() => import('pages/Vacancies'));
const EducationProgramsPage = lazy(() => import('pages/EducationPrograms'));

const RouterWrapper = () => (
  <Suspense>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignInScreen />} />
        <Route path="/sign-up" element={<SignUpScreen />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/professions" element={<Professions />} />
        <Route path="/professions/:slug/:id" element={<Profession />} />
        <Route path="/amp/:slug/:id" element={<Profession />} />
        <Route path="/education" element={<EducationProgramsPage />} />
        <Route path="/education/:slug/:id" element={<UniversityPage />} />
        <Route path="/company/:slug/:id" element={<Company />} />
        <Route path="/articles" element={<Posts />} />
        <Route path="/articles/:slug/:id" element={<Post />} />
        <Route path="/consultants" element={<Consultants />} />
        <Route path="/consultants/:slug/:id" element={<Consultant />} />
        <Route path="/mentors" element={<Mentors />} />
        <Route path="/mentors/:slug/:id" element={<Mentor />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:slug/:id" element={<Order />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about-us" element={<AboutPage extended={true} />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/policy/refund" element={<RefundPolicy />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/vacancies" element={<Vacancies />} />
        <Route path="/policy/privacy" element={<PrivacyPolicy />} />
        <Route path="/policy/cookie" element={<CookiePolicy />} />
        <Route path="/subscription" element={<SubscribeType />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/professions/page/:num" element={<Professions />} />
        <Route path="/articles/page/:num" element={<Posts />} />
        <Route path="/consultants/page/:num" element={<Consultants />} />
        <Route path="/mentors/page/:num" element={<Mentors />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <CookieConsent />
    </Router>
  </Suspense>
);

export default RouterWrapper;
