import config from 'config';
import storage from 'helpers/storage';
import { logout } from 'actions/auth';
import { showServiceMessage } from 'actions/error';
import objectProxy from './objectProxy';
const { backendUrl } = config;

export const API_URL = backendUrl + (backendUrl.charAt(backendUrl.length - 1) !== '/' ? '/' : '');

const getResponseBody = async (response = {}, options) => {
  const contentType = response.headers.get('content-type') || '';

  if (contentType.includes('application/json')) {
    if (options?.rawFile && response.status === 200) {
      const blob = await response.blob();
      return blob;
    }
    const json = await response.json();
    return objectProxy(json, { headers: response.headers });
  }

  if (contentType.includes('text/html')) {
    const text = await response.text();
    return text;
  }

  const blob = await response.blob();
  return blob;
};

async function createRequest(request, action, dispatch, payload, options, isIgnore) {
  if (!navigator.onLine) {
    dispatch(showServiceMessage(new Error('ConnectionFailed')));
    return;
  }

  const { url, ...conf } = request;
  const { method } = request;

  dispatch({ type: action, payload, body: request.body, url, method });

  const requestData = {
    ...conf,
    cache: 'reload',
    headers: {
      ...conf.headers,
      token: storage.getItem('token'),
      'X-API-SessionId': sessionStorage.getItem('sessionId')
    }
  };

  try {
    const response = await fetch(url, requestData);

    if (!navigator.onLine) {
      dispatch(showServiceMessage(new Error('ConnectionFailed')));
    }

    const responseBody = await getResponseBody(response, options);

    const errors = [].concat(responseBody.error, responseBody.errors).filter(Boolean);

    if (response.status === 401) {
      dispatch(logout());
      throw new Error('401 unauthorized');
    }

    if (response.status === 403) {
      const error = new Error('403 forbidden');
      error.details =
        responseBody?.error?.details || responseBody?.error?.message || responseBody?.message;
      throw error;
    }

    if (response.status === 413) {
      throw new Error('413 Payload Too Large');
    }

    if (response.status === 404) {
      throw new Error('404 not found');
    }

    if (errors.length) {
      const errorMessage = errors.shift();
      const error = new Error(
        errorMessage?.error?.message || errorMessage?.message || errorMessage?.msg || errorMessage
      );
      error.details = errorMessage.details;
      error.response = responseBody;
      throw error;
    }

    const responseData =
      'data' in responseBody
        ? objectProxy(responseBody.data, { headers: response.headers })
        : responseBody;

    if (responseBody.meta || responseBody.pagination) {
      responseData.meta = responseBody.pagination || responseBody.meta;
    }

    dispatch({
      type: `${action}_SUCCESS`,
      payload: responseData,
      request: payload,
      url,
      method,
      body: request.body
    });
    return responseData;
  } catch (error) {
    if (error.message === 'Failed to fetch' && navigator.onLine && !isIgnore) {
      dispatch(showServiceMessage(error));
    }

    dispatch({
      type: `${action}_FAIL`,
      payload: error,
      url,
      method,
      body: request.body,
      request: payload
    });

    throw error;
  }
}

export function get(url, action, dispatch, payload, options = {}) {
  const request = {
    url: API_URL + url,
    method: 'get'
  };

  return createRequest(request, action, dispatch, payload, options);
}

export function post(url, body, action, dispatch, additional = {}, options = {}, isIgnore) {
  const { headers } = options;
  const request = {
    url: API_URL + url,
    method: 'post',
    headers: { 'Content-Type': 'application/json', ...(headers || {}) },
    body: JSON.stringify(body)
  };

  return createRequest(request, action, dispatch, { ...body, ...additional }, {}, isIgnore);
}

export function put(url, body, action, dispatch, additional = {}, options = {}) {
  const { headers } = options;

  const request = {
    url: API_URL + url,
    method: 'put',
    headers: { 'Content-Type': 'application/json', ...(headers || {}) },
    body: JSON.stringify(body)
  };

  return createRequest(request, action, dispatch, { ...body, ...additional });
}

export function del(url, body, action, dispatch, payload) {
  const request = {
    url: API_URL + url,
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  return createRequest(request, action, dispatch, payload);
}
