import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-translate';
import Typography from '@mui/material/Typography';
import Helmet from 'components/Helmet';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import config from 'config';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: 1030,
  margin: '0 auto',
  gap: 60,
  padding: '50px 0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    padding: '0',
    gap: 0
  }
}));

const LeftSidebar = styled('div')(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}));

const RightSidebar = styled('div')(({ theme }) => ({
  width: '50%',
  height: 325,
  willChange: 'transform, opacity',
  '& img': {
    width: 285,
    height: 325
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    height: 289,
    '& img': {
      width: 225,
      height: 255
    }
  },
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'center',
    '& img': {
      marginBottom: 30
    }
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 30,
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
    textAlign: 'center'
  }
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 20,
  marginTop: 30,
  maxWidth: 300,
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    gap: 15
  }
}));

const ButtonRounded = styled(Button)(() => ({
  borderRadius: 25,
  color: '#000',
  fontSize: 16,
  padding: '11px 18px'
}));

const ButtonText = styled('div')(({ theme }) => ({
  color: '#000',
  textAlign: 'center',
  cursor: 'pointer',
  position: 'relative',
  marginTop: 5,
  '& p': {
    display: 'inline-block',
    '& > span': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginLeft: -90,
      marginTop: -14,
      borderBottom: '1px solid #000',
      zIndex: 2,
      fontSize: 16,
      lineHeight: '19px',
      [theme.breakpoints.down('md')]: {
        marginLeft: -80
      }
    }
  },
  '& > span': {
    display: 'block',
    width: 160,
    height: 0,
    position: 'absolute',
    top: 8,
    left: '50%',
    marginLeft: -90,
    zIndex: 1,
    WebkitBoxShadow: '0px 0px 11px 3px rgba(185,248,51,1)',
    MozBoxShadow: '0px 0px 11px 3px rgba(185,248,51,1)',
    boxShadow: '0px 0px 11px 3px rgba(185,248,51,1)',
    [theme.breakpoints.down('md')]: {
      marginLeft: -80
    }
  }
}));

const ShowSM = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block'
  }
}));

const HideSM = styled('div')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const TypographyStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    lineHeight: '20px'
  }
}));

const Start = ({ handleOpen }) => {
  const navigate = useNavigate();
  const t = useTranslate('Onboarding');

  const handleRedirectProfessions = React.useCallback(() => {
    navigate('/professions');
  }, [navigate]);

  return (
    <>
      <Helmet />
      <Wrapper>
        <LeftSidebar>
          <HideSM>
            <Title variant={'h1'}>{t('IntroTitle')}</Title>
          </HideSM>

          <TypographyStyled>{t('IntroText')}</TypographyStyled>

          <Actions>
            <ButtonRounded size="small" variant={'contained'} onClick={handleOpen}>
              {t('WantToChose')}
            </ButtonRounded>

            <ButtonRounded size="small" variant={'outlined'} onClick={handleOpen}>
              {t('KnowProfession')}
            </ButtonRounded>

            <ButtonText size="small" onClick={handleRedirectProfessions}>
              <Typography>
                <span>{t('HaveAccount')}</span>
              </Typography>
              <span />
            </ButtonText>
          </Actions>
        </LeftSidebar>
        <RightSidebar>
          <img
            src={`${config.imageSource}/9cbb1a99-45f2-4677-259d-f03e92932f00/w285h325`}
            alt="Troll Illustration"
            priority="high"
            fetchpriority="high"
            width={285}
            height={325}
            loading="eager"
          />
        </RightSidebar>

        <ShowSM>
          <Title variant={'h1'}>{t('IntroTitle')}</Title>
        </ShowSM>
      </Wrapper>
    </>
  );
};

export default Start;
