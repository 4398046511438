import React, { lazy, Suspense, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { TranslatorProvider } from 'react-translate';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import Routes from 'routes';
import translation from 'translation';
import theme from 'theme';
import store from 'store';
const SnackbarWrapper = lazy(() => import('components/Snackbars'));
const ServiceMessage = lazy(() => import('components/ServiceMessage'));
const AdditionServices = lazy(() => import('components/AdditionServices'));
const Auth = lazy(() => import('components/Auth'));

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const App = () => {
  const [showDelayedComponents, setShowDelayedComponents] = useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowDelayedComponents(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(theme)}>
        <TranslatorProvider translations={translation}>
          <Provider store={store}>
            <Suspense fallback={null}>
              <ServiceMessage ServiceMessage>
                <Routes />
              </ServiceMessage>
            </Suspense>

            {showDelayedComponents ? (
              <Suspense fallback={null}>
                <Auth>
                  <SnackbarWrapper />
                  <AdditionServices />
                </Auth>
              </Suspense>
            ) : null}
          </Provider>
        </TranslatorProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

root.render(<App />);
