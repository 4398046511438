import * as api from 'services/api';
import { addError } from 'actions/error';
import storage from 'helpers/storage';

export const createOnboarding = (body) => (dispatch) =>
  api.post('onboarding', body, 'CREATE_ONBOARDING', dispatch).catch((error) => {
    dispatch(addError(new Error('CREATE_ONBOARDING_ERROR')));
    return error;
  });

export const transferOnboarding = (id, body) => (dispatch) =>
  api
    .post(`onboarding/${id}/transfer`, body || {}, 'TRANSFER_ONBOARDING', dispatch)
    .catch((error) => {
      dispatch(addError(new Error('TRANSFER_ONBOARDING_ERROR')));
      return error;
    });

export const updateOnboarding = (id, body, finish) => (dispatch) =>
  api
    .put(`onboarding/${id}${finish ? '?finish=true' : ''}`, body, 'UPDATE_ONBOARDING', dispatch)
    .catch((error) => error);

export const handleTransferOnboarding = async (dispatch) => {
  const onboarding = storage.getItem('onboarding');
  const id = (JSON.parse(onboarding) || {})._id;
  if (!id) return;
  await dispatch(updateOnboarding(id, {}, true));
  await dispatch(transferOnboarding(id, {}));
  storage.removeItem('onboarding');
};
