import React, { Suspense, lazy } from 'react';
import { getLayout } from 'actions/layouts';
import { useSelector, useDispatch } from 'react-redux';
import Onboarding from 'pages/Onboarding';
const About = lazy(() => import('./components/About'));
const Services = lazy(() => import('./components/Services'));
const Media = lazy(() => import('./components/Media'));
const HomeProfessions = lazy(() => import('./components/HomeProfessions'));
const Footer = lazy(() => import('components/Footer'));
const LinearProgress = lazy(() => import('components/LinearProgress'));
const MainLayout = lazy(() => import('layouts/MainLayout'));
const Header = lazy(() => import('components/Header'));
const Helmet = lazy(() => import('components/Helmet'));
const LazyComponent = lazy(() => import('components/LazyComponent'));

const Home = () => {
  const dispatch = useDispatch();
  const [texts, setTexts] = React.useState(null);
  const [isAboutVisible, setIsAboutVisible] = React.useState(false);
  const [isServicesVisible, setIsServicesVisible] = React.useState(false);
  const [isMediaVisible, setIsMediaVisible] = React.useState(false);
  const [isHomeProfessionsVisible, setIsHomeProfessionsVisible] = React.useState(false);

  const pageInfo = useSelector((state) => state.pages.state.home_info);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(getLayout('home', 'home_info'));
      if (result instanceof Error) return;
      setTexts(result.layout[0]);
    };

    if (pageInfo) {
      setTexts(pageInfo.layout[0]);
    } else {
      fetchData();
    }
  }, [dispatch, pageInfo]);

  return (
    <>
      <Helmet page={'home'} />
      <MainLayout>
        <Header />
        <Onboarding />
        <Suspense fallback={<LinearProgress />}>
          <LazyComponent component={About} texts={texts} setIsVisible={setIsAboutVisible} />
        </Suspense>
        {isAboutVisible && (
          <Suspense fallback={<LinearProgress />}>
            <LazyComponent component={Services} texts={texts} setIsVisible={setIsServicesVisible} />
          </Suspense>
        )}
        {isServicesVisible && (
          <Suspense fallback={<LinearProgress />}>
            <LazyComponent component={Media} texts={texts} setIsVisible={setIsMediaVisible} />
          </Suspense>
        )}
        {isMediaVisible && (
          <Suspense fallback={<LinearProgress />}>
            <LazyComponent
              component={HomeProfessions}
              texts={texts}
              setIsVisible={setIsHomeProfessionsVisible}
            />
          </Suspense>
        )}
        {isHomeProfessionsVisible && (
          <Suspense fallback={<LinearProgress />}>
            <LazyComponent component={Footer} />
          </Suspense>
        )}
      </MainLayout>
    </>
  );
};

export default Home;
